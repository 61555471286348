<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">微环境</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    label="设备"
                    prop="JCDID"
                    v-if="formdata.dataDic.SFFSBH != 0"
                >
                    <el-select
                        v-model="formdata.dataDic.JCDID"
                        placeholder="请选择设备"
                        :disabled="true"
                    >
                        <el-option
                            v-for="(item, index) in SbList"
                            :key="`${item.ID}-${index}`"
                            :label="item.SBMC"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间" prop="JCSJ">
                    <el-date-picker
                        v-model="formdata.dataDic.JCSJ"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"
                        :disabled="!isEdit"
                        placeholder="请选择时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="风向" prop="FX">
                    <el-input
                        v-model="formdata.dataDic.FX"
                        placeholder="请输入风向"
                        :maxlength="50"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="风速" prop="FS">
                    <el-input
                        v-model="formdata.dataDic.FS"
                        placeholder="请输入风速"
                        @input="toDecimal($event, 'FS')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m/s</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="雨量" prop="YL">
                    <el-input
                        v-model="formdata.dataDic.YL"
                        placeholder="请输入雨量"
                        @input="toDecimal($event, 'YL')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="气温" prop="WD">
                    <el-input
                        v-model="formdata.dataDic.WD"
                        placeholder="请输入气温"
                        @input="toDecimal($event, 'WD')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">℃</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="相对湿度" prop="SD">
                    <el-input
                        v-model="formdata.dataDic.SD"
                        placeholder="请输入相对湿度"
                        @input="limitPercent($event, 'SD')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="气压" prop="QY">
                    <el-input
                        v-model="formdata.dataDic.QY"
                        placeholder="请输入气压"
                        @input="toDecimal($event, 'QY')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">Pa</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="太阳辐射" prop="TYFS">
                    <el-input
                        v-model="formdata.dataDic.TYFS"
                        placeholder="请输入太阳辐射"
                        @input="toDecimal($event, 'TYFS')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">mwh/cm²</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "zr_whj",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020502",
                dataDic: {
                    JCDID: "",
                    JCSJ: "",
                    FX: "",
                    FS: "",
                    YL: "",
                    WD: "",
                    SD: "",
                    QY: "",
                    TYFS: "",
                },
            },
            SGLXList: [],
            SbList: [],
        };
    },
    mounted() {
        this.getSbList();
    },
    methods: {
        ...mapActions(["GetGLXList"]),
        async getSbList() {
            let res = await this.GetGLXList({
                itemcode: "sbqd",
                lm: "16",
            });
            this.SbList = res.ResultValue || [];
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
